:root {
	--primary-color: #AFBF33;
	--main-bg-color: #e9f0f5;
	--responsive-sm: 640px;

	--shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

	/* Colors */
	--col-grey-900: #111827;
	--col-grey-800: #1F2937;
	--col-grey-700: #374151;
	--col-grey-600: #4B5563;
	--col-grey-500: #6B7280;
	--col-grey-400: #9CA3AF;
	--col-grey-300: #D1D5DB;
	--col-grey-200: #E5E7EB;
	--col-grey-100: #F3F4F6;
	--col-grey-50: #F9FAFB;
	--col-white: #fff;

	--col-primary-900: #1E3A8A;
	--col-primary-800: #1E40AF;
	--col-primary-700: #1D4ED8;
	--col-primary-600: #2563EB;
	--col-primary-500: #3B82F6;
	--col-primary-400: #60A5FA;
	--col-primary-300: #93C5FD;
	--col-primary-200: #BFDBFE;
	--col-primary-100: #DBEAFE;
	--col-primary-50: #EFF6FF;

	--col-green-900: #064E3B;
	--col-green-800: #065F46;
	--col-green-700: #047857;
	--col-green-600: #059669;
	--col-green-500: #10B981;
	--col-green-400: #34D399;
	--col-green-300: #6EE7B7;
	--col-green-200: #A7F3D0;
	--col-green-100: #D1FAE5;
	--col-green-50: #ECFDF5;

	--col-orange-900: #78350F;
	--col-orange-800: #92400E;
	--col-orange-700: #B45309;
	--col-orange-600: #D97706;
	--col-orange-500: #F59E0B;
	--col-orange-400: #FBBF24;
	--col-orange-300: #FCD34D;
	--col-orange-200: #FDE68A;
	--col-orange-100: #FEF3C7;
	--col-orange-50: #FFFBEB;

	--col-red-900: #7F1D1D;
	--col-red-800: #991B1B;
	--col-red-700: #B91C1C;
	--col-red-600: #DC2626;
	--col-red-500: #EF4444;
	--col-red-400: #F87171;
	--col-red-300: #FCA5A5;
	--col-red-200: #FECACA;
	--col-red-100: #FEE2E2;
	--col-red-50: #FEF2F2;


	/* Font sizes used */
	--fs-8: 8pt;
	--fs-9: 9pt;
	--fs-11: 11pt;
	--fs-13: 13pt;
	--fs-16: 16pt;
	--fs-18: 18pt;
	--fs-24: 24pt;

	/* Font stacks */
	// --font-stack-default: Lato, sans-serif;

	/* Units */
	--u-1: 1px;
	--u-2: 2px;
	--u-4: 4px;
	--u-6: 6px;
	--u-8: 8px;
	--u-12: 12px;
	--u-16: 16px;
	--u-24: 24px;
	--u-32: 32px;
	--u-48: 48px;
	--u-64: 64px;
	--u-96: 96px;
	--u-128: 128px;
	--u-192: 192px;
	--u-256: 256px;
	--u-384: 384px;
	--u-512: 512px;
	--u-768: 768px;
	--u-960: 960px;
	--u-1200: 1200px;
	--u-1600: 1600px;
	--u-2400: 2400px;

}

html, body {
	width: 100%;
}

body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--main-bg-color);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
	background: white;
	img {
		padding: 1em;
		width: 12em;
	}
}

h1 {
	font-weight: 800;
	font-size: 1.875rem;
	line-height: 2.25rem;
	letter-spacing: -0.025em;

	@media (min-width: 640px) {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
}

table,
table thead th,
table tbody tr,
table tr,
table th,
table tbody td {
	border-collapse: separate;
	border-spacing: 0;
}

aside.sidebar {
	position: sticky;
	position: -webkit-sticky; /* For Safari */

	max-height: calc(100vh - 40px);
	overflow: auto;
}

.popover-arrow {
	margin: 0;

	&,
	&::before {
		position: absolute;
		width: 8px;
		height: 8px;
		z-index: -1;
	}

	&::before {
		content: '';
		transform: rotate(45deg);
		background: black;
	}
}


.bold-value {
	font-weight: bold;
}


.ReactCollapse--collapse {
	transition: height 300ms;
}

button svg {
	display: inline-block;
}

.tooltip {
	position: absolute;
	right: 0;
	background: #333;
	line-height: 1.2em;
	/*margin-left: -120px;*/
	/*width: 240px;*/
	max-width: 240px;
	width: max-content;
	//transform: translateX(-50%);
	z-index: 200;
	text-align: left;
	color: white;
	font-weight: normal;
	font-size: 10pt;
	padding: 8px;
	text-transform: initial;
	border-radius: 2px;
	word-wrap: break-word !important;
	white-space: normal;
	animation-delay: 3500ms;
}

.tooltip-front-edge {
	left: 120%
}

.tooltip-rear-edge {
	left: 20%;
}

.tooltip-top {
	bottom: calc(100% + 4px);
}

.tooltip-top::after {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	bottom: -5px;
	left: calc(90% - 5px);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #333;
}

.tooltip-bottom {
	top: calc(100%);
}

.tooltip-bottom::after {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	top: -5px;
	left: calc(50% - 5px);
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid var(--col-grey-800);
}

.tooltip-front-edge::after {
	left: calc(20% - 5px);
}

.hover-bubble {
	position: relative;
}

.hover-bubble .tooltip {
	display: none;
}

.hover-bubble:hover .tooltip {
	display: block;
}

.input-editable {
	box-shadow: 0 0 4px inset rgba(0, 0, 0, 0.05);
}

.longform-text a {
	color: var(--col-primary-500);
	text-decoration: underline;
}

.faq-collapsable-area-label {
	display: flex;
	flex-direction: row;
	align-items: center;
	user-select: none;
	font-weight: bold;
}

.faq-collapsable-area-icon {
	width: 30px;
	padding-left: 4px;
	position: relative;
	top: 3px;
}

.faq-collapsable-area-icon .iconify {
	transition: transform 200ms ease;
}

.faq-collapsable-area-header-open .faq-collapsable-area-icon .iconify {
	transform: rotate(90deg);
}

.faq-collapsable-area {
	border-radius: 4px;
	padding: 8px;
	margin-bottom: 8px;

	background: var(--col-primary-50);
	border: 1px solid var(--col-primary-300);
	color: var(--col-primary-900);
}

.faq-collapsable-area-content {
	padding-left: 30px;
	padding-top: 8px;
	padding-bottom: 8px;
	line-height: 1.5rem;
}

.faq-collapsable-area-content ul,
.longform-text ul {
	list-style-type: disc;
	padding-left: 24px;
}

.faq-collapsable-area-content li,
.longform-text li {
	padding-bottom: 4px;
}

.faq-collapsable-area-content a {
	text-decoration: underline;
	color: var(--col-primary-600);
}

nav {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	height: 60px;
}

nav a {
	height: 100%;
	padding: 0 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background 150ms ease;
	color: var(--col-primary-600);
}

nav a:hover {
	background: var(--col-primary-100);
	border-color: var(--col-primary-200);
}

.modal-content {
	padding: 16px;
}

.modal-toolbar {
	padding-left: 16px;
}

.modal-toolbar .modal-title {
	font-weight: bold;
	color: var(--col-grey-700);
	font-size: var(--fs-16);
}


/********** DETAILWINDIFY OPERATIONS **********/

* {
	box-sizing: border-box;
}

html, body {
	color: var(--col-grey-700);
	line-height: 1.5;
}

.topbar {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.05);
}

.topbar-content {
	margin: 0 auto;
	max-width: 90rem;
	height: var(--u-64);
	display: flex;
	justify-content: space-between;;
}

.topbar-content nav {
	height: 100%;
}

.topbar-content nav a {
	text-decoration: none;
}

main {
	margin: 0 auto;
	max-width: calc(90rem + var(--u-8) + var(--u-8));
	padding: var(--u-8);
	padding-top: var(--u-24);
}

h1, h2, h3, h4, h5, h6 {
	padding: 0;
	margin: 0;
	color: var(--col-grey-800);
}

h2 {
	font-size: var(--fs-16);
	font-weight: 500;
}

h3 {
	font-size: var(--fs-13);
	font-weight: 600;
	color: var(--col-grey-600);
	padding-bottom: var(--u-8);
	letter-spacing: -0.3px;
}

h3 svg {
	position: relative;
	top: 4px;
	font-size: var(--fs-16);
	margin-right: var(--u-4);
}

h4, .cost-col-title {
	font-size: var(--fs-11);
	font-weight: 500;
	color: var(--col-grey-600);
	letter-spacing: -0.2px;
}

h4 {
	padding-bottom: var(--u-4);
}

.icon-blue {
	color: var(--col-primary-400);
}

p {
	margin: var(--u-8) 0;
	padding: 0;
}

.two-col-layout {
	display: flex;
	gap: var(--u-48);

	.column {
		flex: 1;
	}

	aside {
		width: calc(270px + var(--u-16));
	}
}

@media (max-width: 1200px) {
	.desktop-only {
		display: none;
	}
}

.col-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--u-48);
}

.col-3 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: var(--u-48);
}

.hero-text {
	font-size: var(--fs-13);
}

/* Cards */
.card {
	background: var(--col-grey-50);
	box-shadow: var(--u-1) var(--u-2) 4px rgba(0, 0, 0, 0.1), var(--u-1) var(--u-2) 16px rgba(0, 0, 0, 0.05);
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: var(--u-24);
	transition: box-shadow 250ms ease-out;
}

.card.card-active {
	box-shadow: var(--u-1) var(--u-2) 4px rgba(0, 0, 0, 0.15), var(--u-1) var(--u-2) 24px rgba(0, 0, 0, 0.1);
}

.card.card-clickable .card-top {
	cursor: pointer;
}

.card-top {
	background: var(--col-white);
	padding: var(--u-24) var(--u-16);
	display: grid;
	grid-template-columns: var(--u-64) 1fr;
	gap: var(--u-16);
	align-items: center;
}

.card-top-icon-segment {
	display: flex;
	justify-content: center;
}

.icon-circle {
	width: var(--u-48);
	height: var(--u-48);
	border-radius: 50%;
	background: var(--col-primary-700);
	color: var(--col-white);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
}

.card-top-title {
	h2 {
		margin: 0;
		padding: 0;
		letter-spacing: -0.3px;
		line-height: 1.35;
	}

	span {
		color: var(--col-grey-500);
	}
}

.card-content {
	padding: var(--u-24) var(--u-96);
}

.card-btn-area {
	padding: var(--u-16) var(--u-96) var(--u-24) var(--u-96);

	.btn {
		font-size: var(--fs-13);
		letter-spacing: -0.2px;
	}
}

.card-summary-wrapper {
	background: var(--col-white);
	padding: var(--u-16);
}

.card-summary {
	background: var(--col-green-100);
	color: var(--col-green-800);
	border-radius: var(--u-4);
	display: grid;
	gap: var(--u-16);
	padding: var(--u-16) 0;
	grid-template-columns: var(--u-64) 1fr var(--u-64);

	.card-summary-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5em;
		text-align: center;
		color: var(--col-green-700);
	}

	&.card-summary-error {
		background: var(--col-red-100);
		color: var(--col-red-800);
	}

	small {
		font-size: var(--fs-11);
		color: var(--col-green-700);
	}
}

.summary-edit-btn {
	background: var(--col-green-500);
	color: white;
	border: 0;
	border-radius: 4px;
	width: var(--u-48);
	height: var(--u-48);
	transition: background 150ms ease-out;

	&:hover {
		background: var(--col-green-600);
	}

	svg {
		zoom: 150%;
	}
}

.card-summary-error .summary-edit-btn {
	background: var(--col-red-500);

	&:hover {
		background: var(--col-red-600);
	}
}

.remark {
	font-size: var(--fs-11);
	color: var(--col-grey-500);
	font-weight: normal;
	padding-top: var(--u-8);

	max-width: 40rem;

	a {
		color: var(--col-primary-500);
	}
}

.hor-separator {
	border: 1px solid var(--col-grey-200);
	margin: var(--u-24) 0;
}

/* Calc Row */
.calc-row {
	.calc-row-content {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		align-items: center;
	}
}

/* Calc Row Term */
.calc-row-term {
	.calc-row-term-content {
		display: flex;
		align-items: center;
	}

	.sign-wrapper {
		width: var(--u-16);
		line-height: var(--u-32);
		height: var(--u-32);
		display: flex;
		align-items: center;
	}

	.calc-row-term-field {
		margin-right: var(--u-4);
		width: var(--u-64);
	}

	.calc-row-term-field.calc-row-term-field-wide {
		width: var(--u-128);
	}

	.calc-row-term-unit {
		font-size: var(--fs-11);
		color: var(--col-grey-500);
	}
}

/* Form controls */
.form-control, select {
	display: block;
	border: 1px solid var(--col-grey-300);
	background: var(--col-white);
	height: var(--u-32);
	padding: 0 var(--u-4);
	text-align: right;
	font-weight: 600;
	color: var(--col-grey-500);
	width: 100%;
	outline: none;
	transition: all 200ms ease;
	box-shadow: 0 0 3px inset var(--col-grey-200);
	font-size: var(--fs-11);
	line-height: var(--u-32);

	&.fc-text-left {
		text-align: left;
	}

	&:focus {
		border-color: var(--col-primary-500);
		box-shadow: 0 0 3px inset var(--col-primary-200);
		border-radius: 0;
	}
}

.calculation-input.calculation-input-uneditable .form-control {
	background: none;
	border: 0;
	box-shadow: none;
}

/* Form fields with icon (e.g. CostRow, CostColumn, CostInput) */
.cost-row {
	h3 {
		padding-bottom: 0;
	}

	& > .remark {
		padding-bottom: var(--u-16);
	}
}

.cost-row-cols {
	display: grid;
	gap: var(--u-8) var(--u-24);

	&.cost-row-cols-1 {
		grid-template-columns: 1fr;
	}

	&.cost-row-cols-2 {
		grid-template-columns: 1fr 1fr;
	}

	&.cost-row-cols-3 {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.cost-row-cols-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	/* TODO: breakpoints */
	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
	}
}

.cost-row-full-width {
	grid-column: 1 / -1;

	/* TODO: breakpoints */
	@media screen and (max-width: 800px) {
		grid-column: 1;
	}
}

.cost-col {
	.cost-col-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: var(--u-8);
	}

	.cost-col-icon {
		padding-top: var(--u-4);
		color: var(--col-primary-600);
		font-size: var(--fs-13);
	}
}

.cost-input {
	.cost-input-field {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: var(--u-4);

		.cost-input-input {
			width: var(--u-128);
		}

		.cost-input-unit {
			flex: 1;
		}

		&.cost-input-field-rev {
			flex-direction: row-reverse;
		}
	}

	.remark {
		padding-top: 0;
		padding-bottom: var(--u-8);
	}
}

.unit {
	color: var(--col-grey-500);
	font-size: var(--fs-11);
}

/* Horizontal column chart */
.hor-chart {
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: column;

	.hor-chart-container {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		gap: var(--u-8);
		width: 100%;
	}

	.column-chart-col {
		height: var(--u-16);
		transition: all 350ms ease-in-out;
	}

	.hor-chart-label {
		font-size: var(--fs-11);
		color: var(--col-grey-700);
	}
}

/* Modals */
.modal-card {
	background: var(--col-white);
	padding: var(--u-24) var(--u-32);
	border-radius: 4px;
	box-shadow: var(--u-1) var(--u-2) 4px rgba(0, 0, 0, 0.1), var(--u-1) var(--u-2) 24px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.btn {
	font-family: inherit;
	text-transform: uppercase;
	font-weight: 600;
	border-radius: 4px;
	border: 0;
	height: var(--u-48);
	line-height: var(--u-48);
	padding: 0 var(--u-16);
	background: none;
	color: var(--col-primary-600);
	cursor: pointer;
	transition: color 150ms ease;

	&:hover {
		color: var(--col-primary-400);
	}
}

.btn.btn-primary {
	background: var(--col-primary-800);
	color: white;
	transition: background 150ms ease;

	&:hover {
		background: var(--col-primary-700);
	}
}

.invisible-btn {
	color: var(--col-grey-500);
	transition: color 200ms ease-out;
	cursor: pointer;

	&:hover {
		color: var(--col-grey-700);
	}
}

.button-area {
	display: flex;
	justify-content: flex-end;
	padding-top: var(--u-8);
	gap: var(--u-16);
}

/* Alerts */
.alert {
	border-left: var(--u-2) solid var(--col-primary-500);
	background: var(--col-primary-100);
	color: var(--col-primary-800);
	font-size: var(--fs-11);
	display: flex;
	padding: var(--u-8);
	padding-right: var(--u-24);
	margin-bottom: var(--u-16);

	h3 {
		color: var(--col-primary-800);
	}
}

.alert-icon {
	width: var(--u-48);
	text-align: center;
	padding-top: var(--u-1);
	font-size: var(--fs-13);
	color: var(--col-primary-500);
}

.alert-content {
	flex: 1;
}

.alert-warning {
	background: var(--col-orange-100);
	color: var(--col-orange-800);
	border-left-color: var(--col-orange-500);

	.alert-icon {
		color: var(--col-orange-500);
	}

	h3 {
		color: var(--col-orange-800);
	}
}

.alert-error {
	background: var(--col-red-100);
	color: var(--col-red-800);
	border-left-color: var(--col-red-500);

	.alert-icon {
		color: var(--col-red-500);
	}

	h3 {
		color: var(--col-red-800);
	}
}

.alert-success {
	background: var(--col-green-100);
	color: var(--col-green-800);
	border-left-color: var(--col-green-500);

	.alert-icon {
		color: var(--col-green-500);
	}

	h3 {
		color: var(--col-green-800);
	}
}

/* Reference system */
.reference {
	display: inline-flex;
	align-items: center;
	font-weight: 500;
	font-size: var(--fs-11);
	background: var(--col-grey-200);
	color: var(--col-grey-600);
	padding: var(--u-2) var(--u-8);
	border-radius: 999px;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.reference-highlight.reference-highlight.reference-highlight.reference-highlight {
	transition: all 150ms ease;
	background: var(--col-primary-500);
	color: white;
	transform: scale(1.1);
}

/* Service cost mix */
.service-mix-summary {
	font-size: var(--fs-11);

	.service-mix-summary-row {
		display: grid;
		grid-template-columns: 1fr 96px 96px;
		color: var(--col-grey-500);
	}

	.service-mix-summary-row :nth-of-type(2),
	.service-mix-summary-row :nth-of-type(3) {
		color: var(--col-grey-700);
		font-weight: 500;
		text-align: right;
		font-variant-numeric: tabular-nums;
	}

	.service-mix-summary-header :nth-of-type(2),
	.service-mix-summary-header :nth-of-type(3) {
		color: var(--col-grey-500);
		font-weight: 400;
		text-align: right;
	}

	.service-mix-summary-footer {
		margin-top: var(--u-8);
		padding-top: var(--u-8);
		border-top: 1px solid var(--col-primary-300);
	}
}

.service-cost {
	display: flex;
	flex-direction: column;
	gap: var(--u-16);

	.service-cost-header {
		display: grid;
		grid-template-columns: var(--u-64) 1fr;
		align-items: center;

		h3 {
			margin: 0;
			padding: 0;
		}
	}

	.service-cost-icon {
		color: var(--col-primary-500);
		display: flex;
		justify-content: center;
		zoom: 150%;
	}

	.service-cost-content {
		display: grid;
		grid-template-columns: var(--u-64) 1fr;
		align-items: flex-start;

		.service-cost-content-checkbox {
			display: flex;
			justify-content: center;

			input[type=checkbox] {
				width: var(--u-16);
				height: var(--u-16);
			}
		}

		.col-2, .col-3 {
			gap: var(--u-16);
		}
	}

	&.service-cost-disabled {
		.service-cost-icon {
			color: var(--col-primary-200);
		}
	}

	.service-cost-fields {
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: var(--u-16);
	}
}

.value-block {
	position: relative;
	border: 1px solid var(--col-grey-200);
	border-radius: var(--u-4);
	box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.1);
	padding-bottom: var(--u-24);

	.value-block-edit-btn {
		button {
			width: var(--u-32);
			height: var(--u-32);
			line-height: var(--u-32);
			position: absolute;
			top: var(--u-4);
			right: var(--u-4);
			background: transparent;
			border: none;
			transition: background 150ms ease;
			border-radius: var(--u-4);
			color: var(--col-grey-500);
			font-size: var(--fs-13);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		button:hover {
			background: var(--col-grey-200);
		}
	}

	&.value-block-editing {
		border-color: var(--col-primary-500);
		background: var(--col-primary-50);

		.value-block-edit-btn button {
			background: var(--col-primary-500);
			color: white;
		}
	}

	.unit {
		white-space: nowrap;
		padding: 0 var(--u-16) 0 var(--u-4);
	}
}

/* Indicators */
.indicator-caption {
	color: var(--col-grey-600);
	font-size: var(--fs-9);
}

.indicator-section {
	display: flex;
	flex-direction: column;
	gap: var(--u-16);

	h3 {
		font-size: 12pt;
		padding-bottom: var(--u-2);
	}
}

.indicator-unit {
	display: inline-flex;
	flex-direction: column;
	gap: var(--u-2);
	align-items: center;
}

.indicator-pill {
	border-radius: 999px;
	background: var(--col-grey-300);
	color: var(--col-grey-700);
	width: var(--u-96);
	padding: var(--u-4) var(--u-8);
	align-items: center;
	vertical-align: middle;
	font-weight: 500;

	.indicator-pill-icon {
		position: relative;
		top: 2px;
		margin-right: var(--u-4);
	}


	&.indicator-pill-unknown {
		background: var(--col-primary-100);
		color: var(--col-primary-700);
	}

	&.indicator-pill-error {
		background: var(--col-red-100);
		color: var(--col-red-700);

		.indicator-pill-icon {
			color: var(--col-red-500);
		}
	}

	&.indicator-pill-warn {
		background: var(--col-orange-100);
		color: var(--col-orange-700);

		.indicator-pill-icon {
			color: var(--col-orange-500);
		}
	}

	&.indicator-pill-ok {
		background: var(--col-green-100);
		color: var(--col-green-700);
	}
}

aside .indicator-section {
	text-align: center;
}

@media screen and (max-width: 1200px) {
	.two-col-layout > aside {
		display: none !important;
	}

	.ReactModal__Content {
		padding: 0 !important;
		padding-top: var(--u-16) !important;
		inset: var(--u-16) var(--u-8) !important;
	}
}